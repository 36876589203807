<template>
  <div class="container global-list-main-container">
    <!-- Header Section -->
    <div class="upper-global-title special-margin">
      <span class=""> Chat inteligente </span>
    </div>

    <!-- Hint Section -->
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"></b-icon>
      <span class="global-form-hint-color">Sugerencia:</span>
      Prueba preguntar "¿Cuáles son las ventas totales del último mes?"
    </div>

    <!-- Main Content -->
    <div class="initial-dasboard-main-container">
      <!-- Chat Section -->
      <div class="initial-dashboard-title-container">
        <h3 class="form-division">Conversación</h3>

        <div class="chat-container global-border-radius global-border-shadow">
          <div class="chat-messages" ref="messageContainer">
            <template
              v-if="currentConversation && currentConversation.messages"
            >
              <div
                v-for="message in currentConversation.messages"
                :key="message.timestamp"
                :class="[
                  'message',
                  message.role === 'user'
                    ? 'user-message'
                    : 'assistant-message',
                ]"
              >
                <div class="message-content">
                  <p>{{ message.content }}</p>
                </div>

                <!-- Analysis Results -->
                <div
                  v-if="message.metadata && message.metadata.analysis"
                  class="message-analysis global-border-radius global-border-shadow"
                >
                  <!-- Raw Results Section -->
                  <div
                    v-if="message.metadata.analysis.results"
                    class="raw-results-container"
                  >
                    <h4 class="section-title">Resultados</h4>

                    <!-- Formatted Results Text -->
                    <p class="formatted-results">
                      {{ message.metadata.analysis.results.formattedResults }}
                    </p>

                    <!-- Raw Results Table -->
                    <div class="raw-results-table">
                      <div
                        v-for="result in message.metadata.analysis.results.raw"
                        :key="result.metric"
                        class="metric-result-card"
                      >
                        <h5 class="metric-title">
                          {{ getMetricName(result.metric) }}
                        </h5>

                        <!-- Total Value -->
                        <div class="total-value">
                          <span class="label">Total:</span>
                          <span class="value">{{
                            formatCurrency(result.total)
                          }}</span>
                        </div>

                        <!-- Breakdown Values -->
                        <div
                          v-if="result.breakdown && result.breakdown.length"
                          class="breakdown"
                        >
                          <h6>Desglose:</h6>
                          <div
                            v-for="item in result.breakdown"
                            :key="item.group"
                            class="breakdown-item"
                          >
                            <span class="group">{{
                              formatGroup(item.group)
                            }}</span>
                            <span class="value">{{
                              formatCurrency(item.value)
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Visualization -->
                  <div
                    v-if="message.metadata.visualization"
                    class="visualization-container"
                  >
                    <!-- Add your visualization component here -->
                  </div>

                  <!-- Key Metrics -->
                  <div
                    v-if="message.metadata.analysis.kpis"
                    class="kpis-container"
                  >
                    <div
                      v-for="kpi in message.metadata.analysis.kpis"
                      :key="kpi.metric"
                      class="kpi-item"
                    >
                      <h4>{{ kpi.description }}</h4>
                      <div class="kpi-value">
                        {{ formatCurrency(kpi.value) }}
                        <span
                          :class="['trend-indicator', `trend-${kpi.trend}`]"
                        >
                          {{ formatCurrency(kpi.comparison) }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Insights -->
                  <div
                    v-if="message.metadata.analysis.insights"
                    class="insights-container"
                  >
                    <h4 class="section-title">Hallazgos Importantes</h4>
                    <ul class="insights-list">
                      <li
                        v-for="insight in message.metadata.analysis.insights"
                        :key="insight.description"
                        :class="[
                          'insight-item',
                          `importance-${insight.importance}`,
                        ]"
                      >
                        <b-icon
                          :icon="getInsightIcon(insight.type)"
                          size="is-small"
                        ></b-icon>
                        {{ insight.description }}
                      </li>
                    </ul>
                  </div>

                  <!-- Recommendations -->
                  <div
                    v-if="message.metadata.analysis.recommendations"
                    class="recommendations-container"
                  >
                    <h4 class="section-title">Recomendaciones</h4>
                    <ul class="recommendations-list">
                      <li
                        v-for="recommendation in message.metadata.analysis
                          .recommendations"
                        :key="recommendation.action"
                        class="recommendation-item"
                      >
                        <b-icon
                          icon="lightbulb-outline"
                          size="is-small"
                        ></b-icon>
                        {{ recommendation.action }}
                        <span
                          :class="[
                            'impact-badge',
                            `impact-${recommendation.impact}`,
                          ]"
                        >
                          {{ recommendation.impact }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="empty-state">
              <b-icon
                icon="chat-processing-outline"
                size="is-large"
                type="is-light"
              ></b-icon>
              <p>Inicia una conversación para analizar tus datos</p>
            </div>
          </div>

          <!-- Chat Input -->
          <div class="chat-input-container">
            <b-field class="chat-input-field">
              <b-input
                v-model="newMessage"
                type="textarea"
                :rows="2"
                placeholder="Haz una pregunta sobre tus datos... (Presiona Enter para enviar)"
                @keyup.enter.native="sendMessage"
                :disabled="isLoading"
                class="chat-textarea"
                :custom-class="'chat-textarea-custom'"
              >
              </b-input>
            </b-field>
            <div class="chat-button-container">
              <b-button
                type="is-primary"
                @click="sendMessage"
                :disabled="!newMessage || isLoading"
                :loading="isLoading"
                class="send-button"
                icon-right="send"
              >
                Enviar
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Available Metrics Section -->
      <div class="initial-dashboard-title-container">
        <h3 class="form-division">Métricas Disponibles</h3>
        <div
          class="metrics-container global-border-radius global-border-shadow"
        >
          <div
            v-for="metric in availableMetrics"
            :key="metric.code"
            class="metric-item"
          >
            <h4>{{ metric.name }}</h4>
            <p>{{ metric.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AnalyticsChat",

  data() {
    return {
      newMessage: "",
      isLoading: false,
      moment: moment,
      conversationId: localStorage.getItem("analyticsConversationId"),
    };
  },

  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    getMetricName(metricCode) {
      const metric = this.availableMetrics.find((m) => m.code === metricCode);
      return metric ? metric.name : metricCode;
    },

    formatGroup(group) {
      // Si el grupo es una fecha, formatearla
      if (moment(group, moment.ISO_8601, true).isValid()) {
        return moment(group).format("MMMM YYYY");
      }
      return group;
    },

    getInsightIcon(type) {
      const icons = {
        highlight: "star-outline",
        trend: "trending-up",
        anomaly: "alert-circle-outline",
        comparison: "compare",
      };
      return icons[type] || "information-outline";
    },

    async loadConversation(conversationId) {
      try {
        await this.$store.dispatch("GET_CONVERSATION", {
          conversationId,
        });
      } catch (error) {
        console.error("Error loading conversation:", error);
        localStorage.removeItem("analyticsConversationId");
        this.conversationId = null;
        return false;
      }
    },

    async createNewConversation() {
      const response = await this.$store.dispatch("CREATE_CONVERSATION", {
        userId: this.$store.getters.USER._id,
      });

      if (response === "Success") {
        // Guardar el ID de la nueva conversación
        const conversationId = this.$store.getters.CURRENT_CONVERSATION._id;
        localStorage.setItem("analyticsConversationId", conversationId);
        this.conversationId = conversationId;
        return true;
      }
      return false;
    },

    async sendMessage() {
      if (!this.newMessage.trim()) return;

      this.isLoading = true;

      try {
        if (!this.currentConversation) {
          // Primero intenta cargar una conversación existente
          if (this.conversationId) {
            const loaded = await this.loadConversation(this.conversationId);
            if (!loaded) {
              const success = await this.createNewConversation();
              if (!success) throw new Error("Error creating conversation");
            }
          } else {
            const success = await this.createNewConversation();
            if (!success) throw new Error("Error creating conversation");
          }
        }

        const response = await this.$store.dispatch(
          "HANDLE_ANALYTICS_MESSAGE",
          {
            conversationId: this.currentConversation._id,
            message: this.newMessage,
          }
        );

        if (response === "Success") {
          this.newMessage = "";
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else {
          this.$buefy.toast.open({
            message: "Error al enviar el mensaje",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        this.$buefy.toast.open({
          message: "Error al procesar el mensaje",
          type: "is-danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    scrollToBottom() {
      const container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
    },
  },

  computed: {
    currentConversation() {
      return this.$store.getters.CURRENT_CONVERSATION;
    },
    availableMetrics() {
      return this.$store.getters.AVAILABLE_METRICS;
    },
  },

  async mounted() {
    this.$store.dispatch("CHANGEACTIVE", "analytcisChat");
    await this.$store.dispatch("GET_METRICS", {
      companyMainName: this.$store.getters.COMPANY_INFORMATION.companyMainName,
      email: this.$store.getters.USER.email,
    });

    // Intentar cargar conversación existente
    if (this.conversationId) {
      await this.loadConversation(this.conversationId);
    }
  },
};
</script>

<style scoped>
@import "../../Global//style/Global.css";
@import "../../Global/style/InitialDashboard.css";

.chat-container {
  height: 500px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  margin-bottom: 20px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.message {
  margin-bottom: 15px;
  max-width: 80%;
}

.user-message {
  margin-left: auto;
  background-color: #e3f2fd;
  border-radius: 15px 15px 0 15px;
  padding: 10px 15px;
}

.assistant-message {
  margin-right: auto;
  background-color: #f5f5f5;
  border-radius: 15px 15px 15px 0;
  padding: 10px 15px;
}

.message-analysis {
  margin-top: 10px;
  padding: 15px;
  background: white;
}

.chat-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.chat-input-field {
  flex: 1;
  width: 100%;
}

.chat-textarea {
  width: 100%;
}

.chat-textarea-custom {
  width: 100% !important;
}

.send-button {
  min-width: 100px;
}

.empty-state {
  text-align: center;
  padding: 40px;
  color: #999;
}

.metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  padding: 20px;
  background: white;
}

.metric-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #7957d5;
}

.metric-item h4 {
  color: #ffffff;
  margin-bottom: 5px;
}

.metric-item p {
  color: #f5f5f5;
  font-size: 0.9em;
  margin-bottom: 0;
}

/* Raw Results Styles */
.raw-results-container {
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.formatted-results {
  margin-bottom: 15px;
  font-size: 1em;
  color: #2c3e50;
  line-height: 1.5;
}

.raw-results-table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

.metric-result-card {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.metric-title {
  color: #7957d5;
  margin-bottom: 10px;
  font-weight: 600;
}

.total-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f1f5f9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.total-value .label {
  font-weight: 500;
  color: #64748b;
}

.total-value .value {
  font-weight: 600;
  color: #2c3e50;
}

.breakdown {
  margin-top: 10px;
}

.breakdown h6 {
  color: #64748b;
  margin-bottom: 8px;
}

.breakdown-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px dashed #e2e8f0;
}

.breakdown-item:last-child {
  border-bottom: none;
}

.breakdown-item .group {
  color: #64748b;
}

.breakdown-item .value {
  font-weight: 500;
  color: #2c3e50;
}

/* KPIs Styles */
.kpis-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.kpi-item {
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.kpi-item h4 {
  color: #2c3e50;
  margin-bottom: 8px;
  font-size: 0.9em;
}

.kpi-value {
  font-size: 1.2em;
  font-weight: 600;
  color: #2c3e50;
}

/* Trend Indicators */
.trend-indicator {
  font-size: 0.9em;
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 4px;
}

.trend-up {
  color: #388e3c;
  background: #e8f5e9;
}

.trend-down {
  color: #d32f2f;
  background: #ffebee;
}

.trend-stable {
  color: #f57c00;
  background: #fff3e0;
}

/* Insights Styles */
.section-title {
  margin-bottom: 15px;
  color: #2c3e50;
  font-weight: 600;
}

.insights-list,
.recommendations-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.insight-item,
.recommendation-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 6px;
}

.insight-item {
  background: #f8f9fa;
}

.importance-high {
  border-left: 4px solid #d32f2f;
}

.importance-medium {
  border-left: 4px solid #f57c00;
}

.importance-low {
  border-left: 4px solid #388e3c;
}

/* Recommendations Styles */
.recommendation-item {
  background: #f8f9fa;
  padding: 10px;
}

.impact-badge {
  font-size: 0.8em;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: auto;
}

.impact-high {
  background: #ffebee;
  color: #d32f2f;
}

.impact-medium {
  background: #fff3e0;
  color: #f57c00;
}

.impact-low {
  background: #e8f5e9;
  color: #388e3c;
}

/* Visualization Container */
.visualization-container {
  margin: 20px 0;
  padding: 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Chat Button Container */
.chat-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .message {
    max-width: 90%;
  }

  .raw-results-table {
    grid-template-columns: 1fr;
  }

  .kpis-container {
    grid-template-columns: 1fr;
  }

  .metrics-container {
    grid-template-columns: 1fr;
  }
}

/* Loading State */
.is-loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Scrollbar Styles */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
